<h1>Open Trivia Database Questions</h1>
<trivia-card></trivia-card>
<ul>
    <li>Click any answer to check your guess.</li>
    <li>Click "New Question" to get a new question.</li>
    <li>Click on the category label so select the categories (selecting none will be handle as if all were selected).</li>
    <li>Click on the difficulty label so select the difficulty.</li>
    <li>Check 'Force unique questions' to let the API remember all the questions you already answered. The set of previous questions is identified via a token. Your browser will remember this token even if you restart it. To reset the pool of questions uncheck this setting or wait 6h after your last request (by that time the data is not available on the server anymore).</li>
</ul>
<a href="https://github.com/muePatrick/joey-triviani">Visit the GitHub Repo for more information.</a>
<p>
    Questions provided by <a href="https://opentdb.com">Open Trivia Database</a> under the "Creative Commons Attribution-ShareAlike 4.0 International License".
</p>