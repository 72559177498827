<div class="box">
    <article class="media">
        <div class="media-content">
            <div class="content">
                <div class="dropdown is-unselectable" [class.is-active]="show_category_menu==true">
                    <div class="dropdown-trigger" style="cursor: pointer;"
                        (click)="show_category_menu=!show_category_menu;show_difficulty_menu=false">
                        <strong>Category: {{question.category}}</strong>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content has-background-info-light">
                            <a class="dropdown-item" *ngFor="let category of opentdb_categories; let id = index"
                                (click)=toggleCategory(category.id)>
                                <label class="checkbox">
                                    <input type="checkbox"
                                        [checked]="opentdb_selected_categories.indexOf(category.id)>=0">
                                    {{category.name}}
                                </label>
                            </a>
                        </div>
                    </div>
                </div>
                <br><br>
                <div class="dropdown is-unselectable" [class.is-active]="show_difficulty_menu==true">
                    <div class="dropdown-trigger" style="cursor: pointer;"
                        (click)="show_difficulty_menu=!show_difficulty_menu;show_category_menu=false">
                        <small>Difficulty: {{question.difficulty}}</small>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content has-background-info-light">
                            <a class="dropdown-item" [class.is-active]="opentdb_difficulty==null"
                                (click)="changeDifficulty(null)">
                                [any]
                            </a>
                            <a class="dropdown-item" [class.is-active]="opentdb_difficulty=='easy'"
                                (click)="changeDifficulty('easy')">
                                easy
                            </a>
                            <a class="dropdown-item" [class.is-active]="opentdb_difficulty=='medium'"
                                (click)="changeDifficulty('medium')">
                                medium
                            </a>
                            <a class="dropdown-item" [class.is-active]="opentdb_difficulty=='hard'"
                                (click)="changeDifficulty('hard')">
                                hard
                            </a>
                        </div>
                    </div>
                </div>
                <br><br>
                <span class="title is-3 is-unselectable">
                    {{question.question | htmlCodes}}
                </span>
                <br><br>
                <div class="answer_list">
                    <button class="button" *ngFor="let answer of question.all_answers; let i = index"
                        (click)="checkResult(i)" [class.is-success]="showAnswer && question.correct_answer_index == i"
                        [class.is-danger]="showAnswer && question.correct_answer_index != i && selectedAnswer == i"
                        [disabled]=showAnswer>
                        {{answer | htmlCodes}}</button>
                </div>
                <br><br>
                <div class="settings">
                    <button class="button is-small is-info is-rounded is-outlined" (click)="updateQuestion()">New
                        Question</button>
                    <label class="checkbox">
                        <input type="checkbox" (click)="updateToken($event)" [checked]="opentdb_session_token!==''">
                        Force unique questions.
                    </label>
                </div>
            </div>
        </div>
    </article>
</div>